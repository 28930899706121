import React from 'react';
import '../../App.css'// Ensure CSS is imported

const TermsAndConditions = () => {
  return (
    <div className="policy-container">
      <h1>Terms and Conditions</h1>
      <p className="last-updated">Last updated on Oct 10 2024</p>
      <p>
        For the purpose of these Terms and Conditions, "we", "us", "our" shall mean DIGIHUMANS TECHNOLOGIES PRIVATE LIMITED.
      </p>
      <ul>
        <li>The content of the pages of this website is subject to change without notice.</li>
        <li>We do not provide any warranties or guarantees regarding the accuracy, timeliness, or suitability of the information.</li>
        <li>Your use of the website is at your own risk.</li>
        <li>All material on the website is owned or licensed by us.</li>
        <li>Unauthorized use of the website’s information is prohibited.</li>
        <li>Any disputes are governed by the laws of India.</li>
      </ul>
    </div>
  );
};

export default TermsAndConditions;
