import React from 'react'
import './brandpromotion.css' 
import brandPromotionImage from '../../assets/brand-ambassador-image1.webp'
import { useState , useRef, useEffect} from 'react'


export const Brandpromotion = ({scrollToSection}) => {
    const brandpromotionRef = useRef(null);
    const [style, setStyle] = useState({});
    const [fixedPoint, setFixedPoint] = useState(null); // Store the scroll position when the div becomes fixed

    useEffect(() => {
        const handleScroll = () => {
            const currentTop = brandpromotionRef.current.getBoundingClientRect().top; // Gets current top of div relative to viewport
            const scrollY = window.pageYOffset || document.documentElement.scrollTop; // Current scroll position

            if (currentTop <= 0 && style.position !== 'fixed') { // Check if div should become fixed
                setFixedPoint(scrollY); // Store the scroll position at which the div becomes fixed
                setStyle({
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    zIndex: 10,
                    boxSizing: 'border-box',
                });
            } else if (fixedPoint !== null && scrollY <= fixedPoint - 100) { // Check if user scrolls back up past the fixed point
                setStyle({}); // Reset styles
                setFixedPoint(null); // Clear the fixed point
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [style, fixedPoint]);

  return (
    <div id='brandpromotion' ref={brandpromotionRef} style={style}>
    <div className="brandpromotion-container">
            <div className="brandpromotion-left-container">
                <img className='image' src={brandPromotionImage} alt="" />

            </div>

            <div className="brandpromotion-right-container">
                <div className="brandpromotion-right-upper-container">
                Elevate Brand Presence with a Digital Brand Ambassador on Social Media


                </div>
                <div className="brandpromotion-right-lower-container">
                Boost your brand's visibility and engagement by employing a digital brand ambassador to represent your products on social media. Our AI-driven ambassadors are tailored to embody your brand's values and aesthetic, ensuring a consistent and captivating online presence. These virtual influencers can connect with your audience in a personal and impactful way, driving increased interest and loyalty. Let our digital ambassadors transform how you reach and resonate with consumers across social platforms.


              </div>
            </div>
        </div>
       

        <div className='side-buttons'>
            <button  className='side-button side-button-1' onClick={() => scrollToSection(1)}>1</button>
            <button   className='side-button side-button-2' onClick={() => scrollToSection(2)}>2</button>
            <button style={{'color': 'black', 'background': 'white'}} className='side-button side-button-3' onClick={() => scrollToSection(3)}>3</button>
            <button className='side-button side-button-4' onClick={() => scrollToSection(4)}>4</button>
            <button className='side-button side-button-5' onClick={() => scrollToSection(5)}>5</button>
        </div>
    </div>
  )
}
