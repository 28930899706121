import React from 'react'
import { useState } from 'react';
import { Header, Usecases, Products } from '../containers';
import { Contact } from '../components';

export const Main = ({scrollToSection}) => {

  const [componentHeight, setComponentHeight] = useState(0);

  






  return (
    <div style={{display: 'flex' , flexDirection: 'column'}}>
        <Header />
          <Usecases scrollToSection={scrollToSection} />
          <Products componentHeight={componentHeight} setComponentHeight={setComponentHeight}/>
    </div>
  )
}
