import React from 'react'
import './products.css'
import { Productcard } from '../../components'
import generalDigiHuman from '../../assets/general-digihuman-image.webp'
import interviewImage from '../../assets/interview-image.webp'
import newtongandhiImage from '../../assets/newtongandhi-image.webp'
import brandPromotionImage from '../../assets/brand-ambassador-image1.webp'
import salesassistantImage from '../../assets/salesassistant-image.webp'
import customersupportImage from '../../assets/customersupport-image2.webp'
import { Contact } from '../../components'



import { useState, useRef, useEffect } from 'react';


export const Products = ({ componentHeight, setComponentHeight}) => {

    const productsRef = useRef(null);
    const [style, setStyle] = useState({});
    const [fixedPoint, setFixedPoint] = useState(null); // Store the scroll position when the div becomes fixed

    

    // useEffect(() => {

    //     const handleScroll = () => {
    //         const rect = productsRef.current.getBoundingClientRect(); // Gets the bounding rectangle of the div
    //         // const bottomOfElement = rect.bottom; // Bottom of the element relative to the viewport
    //         const topOfElement = rect.top;
    //         const componentHeight = rect.height;
    //         const viewportHeight = window.innerHeight; // Gets the viewport height in pixels
    //         setComponentHeight((componentHeight/viewportHeight)*100)
    //         const pointToFixAtPixels = componentHeight - viewportHeight;
    //         const pointToFixAt = (pointToFixAtPixels / viewportHeight)*100
    //         const scrollY = window.pageYOffset

    //         if (topOfElement <= -pointToFixAtPixels && style.position !== 'fixed') {
    //         // Check if the bottom of the div reaches the bottom of the viewport
    //         // if (bottomOfElement <= viewportHeight && style.position !== 'fixed') {
    //             setFixedPoint(window.pageYOffset); // Store the scroll position at which the div becomes fixed
    //             setStyle({
    //                 position: 'fixed',
    //                 top: `${-pointToFixAt}vh`, 
    //                 width: '100%',
    //                 boxSizing: 'border-box',
    //             });
    //         } else if (fixedPoint !== null && scrollY <= fixedPoint - 100) { 
    //             // Check if user scrolls back up past the point where the bottom of the element was at the bottom of the viewport
    //             setStyle({}); // Reset styles
    //             setFixedPoint(null); // Clear the fixed point
    //         }
    //     };



    //     window.addEventListener('scroll', handleScroll);

    //     if(componentHeight === 0){
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     }};

    // }, [style, fixedPoint]);
    
    

  return (
    <>
    <div id='products' ref={productsRef} style={style}> 
        <div className="products-container">
            <div className="products-header-container">
                <div className='products-title'>
                    Products
                </div>
            </div>


            <div className="products-grid">
                <Productcard 
                    image={generalDigiHuman}
                    comingSoon={true}
                    title={"General Purpose Digital Human"}
                    description={"This digital being utilizes GenAI to engage in realistic conversations on a wide array of topics. Whether you're looking for information, entertainment, or just a friendly chat, our digital human is equipped to provide responses that are intelligent and incredibly human-like. This digital companion is entirely free to use, making it accessible to everyone who wishes to explore the next generation of AI-driven communication."}
                />
                <Productcard 
                    image={interviewImage}
                    comingSoon={true}
                    title={"Your Personal Interview Practice Coach"}
                    description={"Perfect your interview skills with our AI-Powered Interview Practice Coach, designed to help you master the art of job interviews and boost your confidence. Our digital coach utilizes GenAI to simulate real-world interview scenarios, providing you with a safe and interactive environment to practice and improve. Upload a job description and your Resume and get started!"}
                />
                <Productcard 
                    image={newtongandhiImage}
                    comingSoon={true}
                    title={"Learn from Newton and Gandhi"}
                    description={"Step into a unique educational experience with our platform, where you can interact and learn directly from digital versions of Sir Isaac Newton and Mahatma Gandhi. Using advanced GenAI technology, we have crafted realistic and intelligent digital representations of these iconic personalities, enabling you to explore their thoughts, philosophies, and discoveries in real-time."}
                />
                <Productcard 
                    image={brandPromotionImage}
                    comingSoon={true}
                    title={"Marketing with a Digital Brand Ambassador"}
                    description={"Embrace the future of marketing with our innovative Digital Brand Ambassador, a cutting-edge solution designed to revolutionize how you promote your products and engage your audience. By integrating advanced GenAI technologies, our digital ambassador offers a unique approach to marketing that enhances brand visibility and consumer connection."}
                />
                <Productcard 
                    image={salesassistantImage}
                    comingSoon={true}
                    title={"Sales Assistant for E-commerce"}
                    description={"Transform your online store with our cutting-edge AI-Powered Sales Assistant, designed to enhance customer engagement, streamline operations, and boost sales. Leveraging GenAI, our virtual sales assistant provides personalized recommendations to enhance shopping experience for every customer."}
                />
                <Productcard 
                    image={customersupportImage}
                    comingSoon={true}
                    title={"Digital Human Customer Support"}
                    description={"Redefine the way you support your customers with our state-of-the-art Digital Human Customer Support Service. Combining the latest advancements in artificial intelligence and realistic digital human technology, this service offers a revolutionary approach to customer interaction that is both efficient and personable."}
                />
            </div>

        </div>
        

    </div>
    <Contact/>
    </>

)
}
