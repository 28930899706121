import React from 'react';
import './PricingTable.css'; // Import the CSS file for styling

const PricingTable = () => {
    return (
        <>
            <div className="pricing-header">
                Plans and Pricing
            </div>
            <div className="pricing-container">
                <div className="plan-card">
                    <h2 className="plan-title">Free</h2>
                    <p className="plan-price">$0</p>
                    <p className="plan-description">per month</p>
                    <button className="plan-button">Sign up now</button>
                    <ul className="plan-features">
                        <li>Interactive Conversations: 0 Minutes</li>
                        <li>AI Assistant Creation</li>
                        <li>Soul Machines Studio Access</li>
                        <li>AI Assistant Templates</li>
                        <li>Gestures</li>
                        <li>Behavior Configuration</li>
                        <li>Voice & Language Configuration</li>
                        <li>Conversational AI Integration</li>
                    </ul>
                </div>

                <div className="plan-card popular">
                    <h2 className="plan-title popular-title">Basic</h2>
                    <p className="plan-price">$12.99</p>
                    <p className="plan-description">per month</p>
                    <button className="plan-button">Start 14-day FREE trial</button>
                    <ul className="plan-features">
                        <li>Interactive Conversations: 40 Minutes</li>
                        <li>AI Assistant Live Deployment: 1 AI Assistant</li>
                        <li>20 Video Downloads</li>
                        <li>Full Screen</li>
                        <li>Reporting Dashboard</li>
                        <li>AI Assistant Creation</li>
                        <li>Soul Machines Studio Access</li>
                        <li>AI Assistant Templates</li>
                        <li>Gestures</li>
                        <li>Behavior Configuration</li>
                        <li>Voice & Language Configuration</li>
                        <li>Avatar Design Tool</li>
                        <li>Conversational AI Integration</li>
                    </ul>
                </div>

                <div className="plan-card">
                    <h2 className="plan-title">Plus</h2>
                    <p className="plan-price">$99.99</p>
                    <p className="plan-description">per month</p>
                    <button className="plan-button">Start 14-day FREE trial</button>
                    <ul className="plan-features">
                        <li>Interactive Conversations: 350 Minutes</li>
                        <li>AI Assistant Live Deployment: 3 AI Assistants</li>
                        <li>100 Video Downloads</li>
                        <li>Full Screen or Custom Embedded</li>
                        <li>Reporting Dashboard</li>
                        <li>AI Assistant Creation</li>
                        <li>Soul Machines Studio Access</li>
                        <li>AI Assistant Templates</li>
                        <li>Gestures</li>
                        <li>Behavior Configuration</li>
                        <li>Voice & Language Configuration</li>
                        <li>Avatar Design Tool</li>
                        <li>Conversational AI Integration</li>
                        <li>Custom LLM Integration</li>
                    </ul>
                </div>

                <div className="plan-card">
                    <h2 className="plan-title">Pro</h2>
                    <p className="plan-price">$2700</p>
                    <p className="plan-description">per month</p>
                    <button className="plan-button">Start 14-day FREE trial</button>
                    <ul className="plan-features">
                        <li>Interactive Conversations: 10,000 Minutes</li>
                        <li>AI Assistant Live Deployment: 6 AI Assistants</li>
                        <li>Unlimited Video Downloads</li>
                        <li>Full Screen or Custom Embedded</li>
                        <li>Reporting Dashboard</li>
                        <li>AI Assistant Creation</li>
                        <li>Soul Machines Studio Access</li>
                        <li>AI Assistant Templates</li>
                        <li>Gestures</li>
                        <li>Behavior Configuration</li>
                        <li>Voice & Language Configuration</li>
                        <li>Avatar Design Tool</li>
                        <li>Conversational AI Integration</li>
                        <li>Custom LLM Integration</li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default PricingTable;
