import React from 'react'
import './interview.css'
import interviewImage from '../../assets/interview-image.webp'
import { useState , useRef, useEffect} from 'react'


export const Interview = ({scrollToSection}) => {
    const interviewRef = useRef(null);
    const [style, setStyle] = useState({});
    const [fixedPoint, setFixedPoint] = useState(null); // Store the scroll position when the div becomes fixed

    useEffect(() => {
        const handleScroll = () => {
            const currentTop = interviewRef.current.getBoundingClientRect().top; // Gets current top of div relative to viewport
            const scrollY = window.pageYOffset || document.documentElement.scrollTop; // Current scroll position

            if (currentTop <= 0 && style.position !== 'fixed') { // Check if div should become fixed
                setFixedPoint(scrollY); // Store the scroll position at which the div becomes fixed
                setStyle({
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    zIndex: 10,
                    boxSizing: 'border-box',
                });
            } else if (fixedPoint !== null && scrollY <= fixedPoint -100) { // Check if user scrolls back up past the fixed point
                setStyle({}); // Reset styles
                setFixedPoint(null); // Clear the fixed point
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [style, fixedPoint]);

   

  return (
    <div id='interview' ref={interviewRef}  style={style}>

    <div className="interview-container">
            <div className="interview-left-container">
                <img className='image' src={interviewImage} alt="" />

            </div>

            <div className="interview-right-container">
                <div className="interview-right-upper-container">
                Ace Your Job Interview with a Specialized Digital Human Interview Coach


                </div>
                <div className="interview-right-lower-container">
                Prepare for your next job interview with a digital human interview coach, designed to simulate real-life interview scenarios. Our AI-powered coach provides you with realistic questions and feedback to create an engaging, lifelike practice environment. This personalized coaching experience helps you develop confidence, improve your communication skills, and refine your answers, ensuring you're fully prepared to make a great impression in any interview.                </div>
            </div>
            
        </div>
        
        <div className='side-buttons'>
            <button style={{'color': 'black', 'background': 'white'}} className='side-button side-button-1' onClick={() => scrollToSection(1)}>1</button>
            <button  className='side-button side-button-2' onClick={() => scrollToSection(2)}>2</button>
            <button className='side-button side-button-3' onClick={() => scrollToSection(3)}>3</button>
            <button className='side-button side-button-4' onClick={() => scrollToSection(4)}>4</button>
            <button className='side-button side-button-5' onClick={() => scrollToSection(5)}>5</button>
        </div>

</div>
  )
}
