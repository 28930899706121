import React from 'react'
import './customersupport.css'
import customersupportImage from '../../assets/customersupport-image.webp'
import { useState , useRef, useEffect} from 'react'

 
export const Customersupport = ({scrollToSection}) => {
    const customersupportRef = useRef(null);
    const [style, setStyle] = useState({});
    const [fixedPoint, setFixedPoint] = useState(null); // Store the scroll position when the div becomes fixed

    useEffect(() => {
        const handleScroll = () => {
            const currentTop = customersupportRef.current.getBoundingClientRect().top; // Gets current top of div relative to viewport
            const scrollY = window.pageYOffset || document.documentElement.scrollTop; // Current scroll position

            if (currentTop <= 0 && style.position !== 'fixed') { // Check if div should become fixed
                setFixedPoint(scrollY); // Store the scroll position at which the div becomes fixed
                setStyle({
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    zIndex: 10,
                    boxSizing: 'border-box',
                });
            } else if (fixedPoint !== null && scrollY <= fixedPoint - 100) { // Check if user scrolls back up past the fixed point
                setStyle({}); // Reset styles
                setFixedPoint(null); // Clear the fixed point
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [style, fixedPoint]);

  return (
    <div id='customersupport' ref={customersupportRef} style={style}>
    <div className="customersupport-container">
            <div className="customersupport-left-container">
                <img className='image' src={customersupportImage} alt="" />

            </div>

            <div className="customersupport-right-container">
                <div className="customersupport-right-upper-container">
                Enhance Customer Satisfaction by Integrating a Customer Support Digital Human


                </div>
                <div className="customersupport-right-lower-container">
                Transform customer support experience by integrating a digital human into your service ecosystem. Our AI-powered digital human is crafted to provide empathetic and personalized support assistance to your customers, enhancing their overall satisfaction and loyalty. our digital human seamlessly interacts with customers, addressing inquiries, resolving issues, and fostering positive interactions. By incorporating this innovative solution into your customer support strategy, you can elevate your brand's reputation and set a new standard for exceptional service in your industry.


              </div>
            </div>
        </div>
       

        <div className='side-buttons'>
            <button  className='side-button side-button-1' onClick={() => scrollToSection(1)}>1</button>
            <button  className='side-button side-button-2' onClick={() => scrollToSection(2)}>2</button>
            <button className='side-button side-button-3' onClick={() => scrollToSection(3)}>3</button>
            <button className='side-button side-button-4' onClick={() => scrollToSection(4)}>4</button>
            <button style={{'color': 'black', 'background': 'white'}}  className='side-button side-button-5' onClick={() => scrollToSection(5)}>5</button>
        </div>
    </div>
  )
}
