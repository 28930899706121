import React from 'react'
import salesassistantImage from '../../assets/salesassistant-image.webp'
import './salesassistant.css'
import { useState, useRef, useEffect } from 'react';

export const Salesassistant = ({scrollToSection}) => {
    const salesassistantRef = useRef(null);
    const [style, setStyle] = useState({});
    const [fixedPoint, setFixedPoint] = useState(null); // Store the scroll position when the div becomes fixed

    useEffect(() => {
        const handleScroll = () => {
            const currentTop = salesassistantRef.current.getBoundingClientRect().top; // Gets current top of div relative to viewport
            const scrollY = window.pageYOffset || document.documentElement.scrollTop; // Current scroll position

            if (currentTop <= 0 && style.position !== 'fixed') { // Check if div should become fixed
                setFixedPoint(scrollY); // Store the scroll position at which the div becomes fixed
                setStyle({
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    zIndex: 10,
                    boxSizing: 'border-box',
                });
            } else if (fixedPoint !== null && scrollY <= fixedPoint - 100) { // Check if user scrolls back up past the fixed point
                setStyle({}); // Reset styles
                setFixedPoint(null); // Clear the fixed point
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [style, fixedPoint]);

  return (
    <div id='salesassistant' ref={salesassistantRef} style={style}>
    <div className="salesassistant-container">
            

            <div className="salesassistant-left-container">
                <div className="salesassistant-left-upper-container">
                Skyrocket Your Sales by Hiring a Digital Sales Assistant For your E-commerce Store</div>

                <div className="salesassistant-left-lower-container">
                Revolutionize your e-commerce business by enlisting the support of a digital sales assistant. Our AI-powered assistant will optimize your sales processes by engaging customers, and driving conversions like never before. From personalized product recommendations to seamless customer service, our assistant works tirelessly to enhance the shopping experience and maximize revenue, ensuring sustained growth and success in the competitive e-commerce landscape.
                 </div>
            </div>

            <div className="salesassistant-right-container">
                <img className='image' src={salesassistantImage} alt="Image of Isaac Newton" />


            </div>
        </div>
       

        <div className='side-buttons'>
            <button  className='side-button side-button-1' onClick={() => scrollToSection(1)}>1</button>
            <button className='side-button side-button-2' onClick={() => scrollToSection(2)}>2</button>
            <button className='side-button side-button-3' onClick={() => scrollToSection(3)}>3</button>
            <button style={{'color': 'black', 'background': 'white'}} className='side-button side-button-4' onClick={() => scrollToSection(4)}>4</button>
            <button className='side-button side-button-5' onClick={() => scrollToSection(5)}>5</button>
        </div>
    </div>
)}
