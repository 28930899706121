import React from 'react'
import './productcard.css'
import { useState } from 'react'


export const Productcard = ({image, comingSoon, title, description}) => {

    const [style, setStyle] = useState({})

    const imageStyle = comingSoon ? { filter: 'blur(3px)', WebkitFilter: 'blur(3px)', opacity: '0.5' } : {}


  return (
    <div>
        <div className="product">
            <div className="product-container">
                <div className="product-image-container">
                    {comingSoon && 
                    <div className='coming-soon-text'>
                        Coming <br />Soon    
                    </div>}
                    <img src={image} style={imageStyle} />
                </div>
                <div className="product-details-container">
                    <div className="product-title-container">
                        {title}
                    </div>

                    <div className="product-description-container">
                        {description}
                    </div>
                </div>
                
            </div>
        </div>
    </div>
  )
}
