import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom

export const Footer = () => {
  return (
    <div id='footer'>
      <div className="footer-container">
        <div>
          <Link to="/terms-and-conditions">Terms and Conditions</Link>  
          <Link to="/privacy-policy">Privacy Policy</Link>  
          <Link to="/cancellation-and-refund">Cancellation and Refund</Link>  
          <Link to="/shippingPolicy">Shipping Policy</Link> 
          <Link to="/contact-us">Contact Us</Link>
        </div>
        <div className="footer-content-container">
          Copyright &copy; 2024 DigiHumans.ai All Rights Reserved
        </div>
      </div>
    </div>
  );
};
