import React from 'react'
import './usecases.css'
import { Interview, Education , Brandpromotion, Salesassistant, Customersupport} from '../../components'



export const Usecases = ({scrollToSection}) => {

  

  return (
<div id='usecases'>
        <Interview scrollToSection={scrollToSection} />
         <Education scrollToSection={scrollToSection}/> 
        <Brandpromotion scrollToSection={scrollToSection}/>
        <Salesassistant scrollToSection={scrollToSection}/>
        <Customersupport scrollToSection={scrollToSection}/>


    </div>  )
}
