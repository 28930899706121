import React from 'react';
import '../../App.css'; // Ensure that your CSS file is imported for styling

const ShippingPolicy = () => {
  return (
    <div className="policy-container">
      <h1>Shipping and Delivery</h1>
      <p className="last-updated">Last updated on Oct 10 2024</p>
      <p>Details about shipping and delivery will be updated here.</p>
    </div>
  );
};

export default ShippingPolicy;
