import React from 'react';
import '../../App.css'; // Ensure CSS is imported

const PrivacyPolicy = () => {
  return (
    <div className="policy-container">
      <h1>Privacy Policy</h1>
      <p className="last-updated">Last updated on Oct 10 2024</p>
      <p>
        This privacy policy sets out how DIGIHUMANS TECHNOLOGIES PRIVATE LIMITED uses and protects any
        information that you give when you visit their website.
      </p>
      <p><strong>Information we may collect:</strong></p>
      <ul>
        <li>Name</li>
        <li>Contact information including email address</li>
        <li>Demographic information such as postcode, preferences, and interests</li>
      </ul>
      <p>
        <strong>How we use your information:</strong>
      </p>
      <ul>
        <li>Internal record keeping</li>
        <li>Improvement of products and services</li>
        <li>Promotional emails</li>
        <li>Market research</li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;

