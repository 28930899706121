/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/iframe-has-title */



import React from 'react'
import './contact.css'
import { FaInstagram, FaLinkedin } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { useState } from 'react';
import { Footer } from '../footer/Footer';
import emailImage from '../../assets/email-image.png'

export const Contact = () => {
    const [formState, setFormState] = useState({
        name: "",
        email: "",
        message: "",
        honeypot: "", // Invisible honeypot field
    });

    const [errorsState, setErrorsState] = useState({
        name: false,
        email: false,
        message: false,
        honeypot: false,
    });

    const [hasErrors, setHasErrors] = useState(false);
    const [submitMessage, setSubmitMessage] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);

    const validateForm = () => {
        const errors = {};

        const nameRegex = /^[a-zA-Z ]+$/;
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        const messageRegex = /^.{75,}$/; // At least 75 characters

        // Name validation
        if (!nameRegex.test(formState.name)) {
            errors.name = true;
        }

        // Email validation
        if (!emailRegex.test(formState.email)) {
            errors.email = true;
        }

        // Message validation
        if (!messageRegex.test(formState.message)) {
            errors.message = true;
        }

        // Honeypot validation (this field should be empty)
        if (formState.honeypot.trim() !== "") {
            errors.honeypot = true;
        }

        setErrorsState(errors);

        // Check if any errors exist
        return Object.keys(errors).length === 0;
    };

   

    const handleSubmit = async (e) => {
        e.preventDefault();  // Prevent default form submission

        if (validateForm()) {
            try {
                // Use '/api' as the base URL to match the proxy configuration
                const response = await fetch('https://api.digihumans.ai/app/contact/home-page/new', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        name: formState.name,
                        email: formState.email,
                        message: formState.message,
                    }),
                });

                if (!response.ok) {
                    const responseText = await response.text();
                    throw new Error(`Error: ${response.status} ${response.statusText} - ${responseText}`);
                }

                const result = await response.json();
                console.log("Form submitted successfully:", result);

                if (result.st === "s") {
                    setSubmitMessage("Form has been submitted successfully!");
                    setFormSubmitted(true);
                } else {
                    throw new Error("Unexpected response from server.");
                }

                // Optionally reset the form after submission
                setFormState({
                    name: '',
                    email: '',
                    message: '',
                    honeypot: '',
                });
            } catch (error) {
                console.error("Error submitting form:", error);
                setSubmitMessage(`Error submitting the form: ${error.message}. Please try again later.`);
                setHasErrors(true);
            }
        } else {
            setSubmitMessage("Please correct the errors in the form.");
            setHasErrors(true);
        }
    };



    return (
        <div id='contact'>
            <div className="contact-container">
                <div className="contact-upper-container">
                    <div className="contact-header">Contact</div>
                </div>
                <div className='contact-lower-container'>
                    <div className="contact-left-container">
                        <div className="contact-form">
                            <label htmlFor="name" className='input-label'>
                                Name<span style={{'color' : 'red'}}>*</span>
                            </label>
                            <input 
                                value={formState.name} 
                                onChange={(e) => setFormState({...formState, name: e.target.value})}  
                                className='input-tag' 
                                type="text" 
                                name="name" 
                                id="name" 
                                placeholder='Enter your full name' 
                                required
                            />
                            {errorsState.name && <p className='error-message'>Name must include only letters.</p>}

                            <label htmlFor="email" className='input-label'>
                                Professional Email<span style={{'color' : 'red'}}>*</span>
                            </label>
                            <input 
                                value={formState.email} 
                                onChange={(e) => setFormState({...formState, email: e.target.value})} 
                                className='input-tag' 
                                type="email" 
                                name="email" 
                                id="email" 
                                placeholder='Enter a valid professional email' 
                                required
                            />
                            {errorsState.email && <p className='error-message'>Enter a valid email like abc@xyz.com</p>}

                            <label htmlFor="message" className='input-label'>
                                Message<span style={{'color' : 'red'}}>*</span>
                            </label>
                            <textarea 
                                value={formState.message} 
                                onChange={(e) => setFormState({...formState, message: e.target.value})} 
                                className='input-tag' 
                                name="message" 
                                id="message" 
                                cols="40" 
                                rows="6" 
                                placeholder='Enter your message...' 
                                required
                            />
                            {errorsState.message && <p className='error-message'>Message must have at least 75 characters.</p>}

                            {/* Invisible honeypot field */}
                            <div style={{ display: 'none' }}>
                                <label htmlFor="honeypot">Do not fill this field</label>
                                <input 
                                    value={formState.honeypot} 
                                    onChange={(e) => setFormState({...formState, honeypot: e.target.value})} 
                                    type="text" 
                                    name="honeypot" 
                                    id="honeypot" 
                                />
                            </div>

                            <button className='submit-button' onClick={handleSubmit} type='submit'>Submit</button>
                            {hasErrors && <p className='error-message' style={{'marginTop' : '0.25rem', 'textAlign' : 'center'}}>{submitMessage}</p>}
                            {formSubmitted && <p className='submitted-message' style={{'marginTop' : '0.25rem', 'textAlign' : 'center', 'color' : 'green'}}>{submitMessage}</p>}
                        </div>
                    </div>
                    <div className='seperator'>or</div>
                    <div className="contact-right-container">
                        <div className="contact-links">
                            <div className='contact-links-email'>
                                <div className='contact-links-email-header'>
                                    Email us at: 
                                </div>
                                <div className="contact-link">
                                    <div className='social-link-label'>
                                        <img src={emailImage} alt="" />
                                    </div>
                                </div>
                            </div>

                            <div className='contact-links-social'>
                                <div className='contact-links-social-header'>
                                    Follow and Message us on: 
                                </div>
                                <a href="https://www.instagram.com/digihumans.ai/" target="_blank"> 
                                    <div className="contact-link"> 
                                        <FaInstagram color="#fff" size={30} />
                                        <div className='social-link-label'>Instagram</div>
                                    </div>
                                </a>
                                
                                <a href="https://www.linkedin.com/company/digihumans-ai/" target='_blank'> 
                                    <div className="contact-link">
                                        <FaLinkedin color="#fff" size={30} />
                                        <div className='social-link-label'>Linkedin</div>
                                    </div>
                                </a>
                                <a href="https://twitter.com/Digihumansai" target='_blank'> 
                                    <div className="contact-link">
                                        <FaXTwitter color="white" size={29}/>
                                        <div className='social-link-label'>Twitter</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
