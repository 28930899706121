import React from 'react';
import '../../App.css'; // Ensure CSS is imported

const ContactPolicy = () => {
  return (
    <div className="policy-container">
      <h1>Contact Us</h1>
      <p className="last-updated">Last updated on Oct 10 2024</p>
      <p>You may contact us using the information below:</p>
      <div className="contact-info">
        <p>
          <strong>E-Mail ID:</strong> <a href="mailto:business@digihumans.ai">business@digihumans.ai</a>
        </p>
        <p>
          <strong>Merchant Legal Entity Name:</strong> DIGIHUMANS TECHNOLOGIES PRIVATE LIMITED
        </p>
        <p>
          <strong>Registered Address:</strong><br />
          Flat-302, Ashray Residency,<br />
          Lane 2, Street-13, Tarnaka, Lallaguda,<br />
          Secunderabad, Hyderabad, TELANGANA 500017
        </p>
        <p>
          <strong>Operational Address:</strong><br />
          Flat-302, Ashray Residency,<br />
          Lane 2, Street-13, Tarnaka, Lallaguda,<br />
          Secunderabad, Hyderabad, TELANGANA 500017
        </p>
      </div>
    </div>
  );
};

export default ContactPolicy;
