import React from 'react'
import newtongandhiImage from '../../assets/newtongandhi-image.webp'
import './education.css'
import { useState, useRef, useEffect } from 'react';

export const Education = ({scrollToSection}) => {
    const educationRef = useRef(null);
    const [style, setStyle] = useState({});
    const [fixedPoint, setFixedPoint] = useState(null); // Store the scroll position when the div becomes fixed

    

    useEffect(() => {
        const handleScroll = () => {
            const currentTop = educationRef.current.getBoundingClientRect().top; // Gets current top of div relative to viewport
            const scrollY = window.pageYOffset || document.documentElement.scrollTop; // Current scroll position

            if (currentTop <= 0 && style.position !== 'fixed') { // Check if div should become fixed
                setFixedPoint(scrollY); // Store the scroll position at which the div becomes fixed
                setStyle({
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    zIndex: 10,
                    boxSizing: 'border-box',
                });
            } else if (fixedPoint !== null && scrollY <= fixedPoint - 100) { // Check if user scrolls back up past the fixed point
                setStyle({}); // Reset styles
                setFixedPoint(null); // Clear the fixed point
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [style, fixedPoint]);

  return (
    <div id='education' ref={educationRef} style={style}>
    <div className="education-container">
            

            <div className="education-left-container">
                <div className="education-left-upper-container">
                Enrich Your Learning Experience with Lessons from Historical Figures</div>

                <div className="education-left-lower-container">
                    Imagine learning Physics from Isaac Newton and about the Indian freedom movement from Mahatma Gandhi. We transform your educational experience by bringing iconic historical personalities to life through digital avatars.  Gain unique insights and perspectives as you converse with these avatars, experiencing firsthand their thoughts, philosophies, and their contributions. This immersive approach not only deepens understanding but also makes learning more dynamic, inspiring, and fun! 
                 </div>
            </div>

            <div className="education-right-container">
                <img className='image' src={newtongandhiImage} alt="Image of Isaac Newton" />

            </div> 
        </div>
        <div className='side-buttons'>
            <button  className='side-button side-button-1' onClick={() => scrollToSection(1)}>1</button>
            <button style={{'color': 'black', 'background': 'white'}}  className='side-button side-button-2' onClick={() => scrollToSection(2)}>2</button>
            <button className='side-button side-button-3' onClick={() => scrollToSection(3)}>3</button>
            <button className='side-button side-button-4' onClick={() => scrollToSection(4)}>4</button>
            <button className='side-button side-button-5' onClick={() => scrollToSection(5)}>5</button>
        </div>
</div>
)}
