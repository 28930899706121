import React from 'react'
import './header.css'
import headerImage from '../../assets/header-image.webp'

//#a82be7
 
export const Header = () => {
  return (
    <div id='header'>
        <div className='header-container'>
            <div className='header-container-left'>
                <div className="header-container-left-upper">
                We Are Revolutionizing How Humans Interact With AI
                </div>
                
                <div className="header-container-left-lower">
                Experience the <span style={{'color' : '#aff', 'textShadow' : '0px 2px 10px white'}}>future of interaction</span>, where technology meets empathy and personalization. <br/> <br />

                <span style={{'color' : '#aff', 'textShadow' : '0px 2px 10px white'}}>Digital humans</span> allow you to leverage the power of GenAI with a Human face! <br /><br />
                    
                    Transform and elevate your customer experiences with <span style={{'color' : '#aff', 'textShadow' : '0px 2px 10px white'}}>personalized, human-centric AI solutions!</span>

                   

              
                   

                </div>


            </div>

            <div className='header-container-right'>
                    <img className='image' src={headerImage} alt="" />
            
            </div>
        </div>
    </div>
  )
}
