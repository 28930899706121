import React from 'react';
import '../../App.css'; // Ensure CSS is imported

const CancellationAndRefund = () => {
  return (
    <div className="policy-container">
      <h1>Cancellation and Refund</h1>
      <p className="last-updated">Last updated on Oct 10 2024</p>
      <p>No cancellations & Refunds are entertained.</p>
    </div>
  );
};

export default CancellationAndRefund;
